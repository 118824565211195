/* MainScreen.css */

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-brand {
    color: #333 !important;
    font-size: 24px;
    font-weight: bold;
}

.container {
    margin-top: 100px;
}

.card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
}

.card h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.btn-primary {
    background: linear-gradient(45deg, #34c759, #30b24a); /* Green gradient */
    border: none;
    border-radius: 8px;
    color: white;
    backdrop-filter: blur(8px);
    transition: background 0.3s, transform 0.3s;
}

.btn-secondary {
    background: linear-gradient(45deg, #ff3b30, #d32f2f); /* Red gradient */
    border: none;
    border-radius: 8px;
    color: white;
    backdrop-filter: blur(8px);
    transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover, .btn-secondary:hover {
    background: linear-gradient(45deg, #4caf50, #388e3c); /* Darker green gradient for primary */
    transform: scale(1.05);
}

.btn-secondary:hover {
    background: linear-gradient(45deg, #e53935, #c62828); /* Darker red gradient for secondary */
    transform: scale(1.05);
}


.btn-success {
    background: linear-gradient(45deg, #6a11cb, #2575fc); /* Purple gradient */
    border: none;
    border-radius: 8px;
    font-size: 18px;
    color: white;
}

.btn-success:hover {
    background: linear-gradient(45deg, #5e0acc, #2065fc);
}

.btn-danger {
    background-color: #ff3b30;
    border-color: #ff3b30;
    border-radius: 8px;
}

.btn-danger:hover {
    background-color: #e63928;
    border-color: #e63928;
}

.input-group {
    margin-bottom: 20px;
}

.generated-script {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(8px);
    padding: 20px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    line-height: 1.5;
    width: 90%;
    max-width: 600px;
    max-height: 60%;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
}

.generated-script.show {
    transform: translateX(-50%) translateY(0);
}

.generated-script .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.generated-script {
    padding-bottom: 20vh; /* Adjust the value as needed */
}

.generated-script.show {
    display: block;
}

.generated-script .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.navbar {
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
    color: #333;
}

.dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    color: #333;
    padding: 10px 20px;
    transition: background-color 0.2s ease-in-out;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
    color: #007aff;
}



.navbar .btn {
    border: none;
    background: transparent;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.navbar .btn:hover {
    color: #007aff;
}

.dropdown-item.disabled {
    color: #777;
    background-color: #ffffff;
    cursor: default;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
}

.dropdown-item a {
    color: rgba(12, 12, 12, 0.77);
    text-decoration: none;
    font-weight: 500;
    margin-left: 5px;
}

.dropdown-item a:hover {
    text-decoration: underline;
    color: #357ae8;
}

.noItem {
    color: rgba(40, 44, 52, 0.7);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}