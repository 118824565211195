body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ececec, #f8f9fa);
}

.login-card {
    background: white;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.login-card h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
}

.login-card p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #555;
    line-height: 1.6;
}

.google-signin-button {
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
    background: linear-gradient(135deg, #d5d5d5, #00b2ff); /* Light grey gradient */
}

.email-signin-button {
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
    background: linear-gradient(135deg, #0d7dad, #8a2be2); /* Purple gradient */
}

.google-signin-button:hover {
    background: linear-gradient(135deg, #d3d3d3, #a9a9a9); /* Light grey gradient on hover */
}

.email-signin-button:hover {
    background: linear-gradient(135deg, #2b6be2, #6a0dad); /* Purple gradient on hover */
}

.google-signin-button:active,
.email-signin-button:active {
    transform: scale(0.95);
}

.google-signin-button:disabled {
    background: linear-gradient(135deg, #d3d3d3, #d3d3d3);
    cursor: not-allowed;
}

.email-signin-button:disabled {
    background: linear-gradient(135deg, #a0c1f4, #a0c1f4);
    cursor: not-allowed;
}


.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.input-field {
    flex-grow: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s;
    height: 46px; /* Ensures consistent height with button */
}

.input-field:focus {
    border-color: #4285F4;
    outline: none;
}

.email-signin-button {
    padding: 12px 16px; /* Adjust padding to make the button smaller */
    height: 46px; /* Ensures consistent height with input field */
}

.divider {
    margin: 20px 0;
    text-align: center;
    font-size: 0.875rem;
    color: #888;
    position: relative;
}

.divider::before,
.divider::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background: #ddd;
}

.divider::before {
    left: 0;
}

.divider::after {
    right: 0;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.message {
    margin-top: 20px;
    font-size: 0.775rem;
    color: #2b0052;
}

.login-footer {
    text-align: center;
    padding: 20px 0;
    color: #777;
    font-size: 0.875rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #f8f9fa;
    border-top: 1px solid #e0e0e0;
}

.login-footer p {
    margin: 0;
}

.login-footer a {
    color: #000000;
    text-decoration: none;
    font-weight: 500;
}

.login-footer a:hover {
    text-decoration: underline;
}

.google-logo {
    margin-right: 8px;
    width: 20px;
    height: 20px;

}


.small-note {
    font-size: 5px;
    color: #888;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
}




.small-note a {
    font-size: 15px;
    color: #888;
    text-decoration: underline;
}

.small-note a:hover {
    text-decoration: none;
    color: #8a2be2;
}